<template>
  <div class="productlist">
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button type="success" @click="productAdd">发布商品</el-button>
    </FormView>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #description>
        <el-table-column label="商品信息" width="380" align="center">
          <template #default="scoped">
            <div class="desc">
              <img :src="scoped.row.image" />
              <span>{{ scoped.row.name }}</span>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #tag>
        <el-table-column label="商品标签" width="200" align="center">
          <template #default="scoped">
            <div class="tabletags">
              <div class="desc" v-if="scoped.row.tags">
                <el-tag
                  closable
                  style="
                    margin: 0 10px;
                    width: 84px;
                    cursor: pointer;
                    background: transparent;
                  "
                  :style="{ 'border-color': item.color, color: item.color }"
                  v-for="(item, i) in scoped.row.tags"
                  @click="editTag(item)"
                  @close="deleteTag(item)"
                >
                  {{ item.name }}
                </el-tag>
              </div>
              <el-button
                v-if="scoped.row.tags?.length < 2 || !scoped.row.tags"
                type="primary"
                size="small"
                @click="addTags(scoped.row)"
              >
                新增标签
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #price>
        <el-table-column label="价格" align="center">
          <template #default="scoped">
            {{ initPrice(scoped.row) }}
          </template>
        </el-table-column>
      </template>
      <template #sort>
        <el-table-column label="排序" align="center">
          <template #default="scoped">
            <div class="sort">
              <span>{{ scoped.row.sort }}</span>
              <el-tooltip
                :visible="scoped.row.sortVisible"
                placement="top"
                effect="light"
              >
                <template #content>
                  <div class="inputbox">
                    <el-input
                      v-model="scoped.row.sortInput"
                      clearable
                      @keyup="numberInput(scoped.row)"
                    />
                    <el-button
                      @click="scoped.row.sortVisible = !scoped.row.sortVisible"
                    >
                      取消
                    </el-button>
                    <el-button type="primary" @click="sortSubmit(scoped.row)">
                      确定
                    </el-button>
                  </div>
                </template>
                <I
                  name="EditPen"
                  @click="scoped.row.sortVisible = !scoped.row.sortVisible"
                ></I>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #state>
        <el-table-column label="销售状态" align="center">
          <template #default="scoped">
            {{ scoped.row.state == 0 ? '下架' : '上架' }}
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" width="380" align="center">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="setItem(scoped.row)"
              v-if="!scoped.row.recommendGoods"
            >
              设为推荐商品
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="cancelItem(scoped.row)"
              v-if="scoped.row.recommendGoods"
            >
              取消推荐商品
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="detailsItem(scoped.row)"
            >
              详情
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="editItem(scoped.row)"
              v-if="scoped.row.state == 0"
            >
              编辑
            </el-button>
            <el-button type="primary" size="small" @click="offItem(scoped.row)">
              {{ scoped.row.state === 1 ? '下架' : '上架' }}
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="deleteItem(scoped.row)"
              v-if="scoped.row.state == 0"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
    <el-dialog
      v-model="tagDialog"
      :title="dialogTitle"
      width="30%"
      class="product_dialog"
      :before-close="dialogClose"
    >
      <el-form
        ref="dialogForm"
        class="dialogForm dialogForm1"
        :model="tagForm"
        :rules="tagFormRules"
        label-width="100px"
      >
        <el-form-item label="标签名称" prop="name">
          <el-input
            v-model="tagForm.name"
            clearable
            placeholder="请输入"
            :maxlength="4"
          />
        </el-form-item>
        <el-form-item label="标签颜色" prop="color" class="colorcont">
          <el-input
            v-model="tagForm.color"
            placeholder="请输入"
            :maxlength="7"
            disabled
          />
          <el-color-picker v-model="tagForm.color" />
          <el-text type="primary" style="cursor: pointer;" @click="changeColor">使用小程序主题色</el-text>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submit"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, onMounted, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import {
  queryCriteria,
  goodsDel,
  changeState,
  goodsSort,
  setGood,
  goodsTagCreate,
  goodsTagUpdate,
  goodsTagDel
} from '@/api/productManagement/productList'
const router = useRouter()
const dialogForm = ref()
const initData = reactive({
  formLabels: [
    {
      label: 'keyword',
      title: '关键字',
      placeholder: '请输入商品编号或商品名称'
    }
  ],
  fromData: {
    // 表单数据
    keyword: ''
  },
  formColumn: 4,
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { prop: 'code', label: '商品货号' },
    { slot: 'description' },
    { slot: 'tag' },
    { slot: 'price' },
    { prop: 'totalStock', label: '库存' },
    { prop: 'salesNum', label: '销量' },
    { slot: 'sort' },
    { slot: 'state' },
    { slot: 'action' }
  ],
  tagDialog: false,
  tagForm: {
    name: '',
    color: '',
    id: '',
    goodsId: ''
  },
  tagFormRules: {
    name: [
      {
        required: true,
        message: '请输入标签名称',
        trigger: 'blur'
      }
    ],
    color: [
      {
        required: true,
        message: '请输入标签颜色',
        trigger: 'blur'
      }
    ]
  },
  dialogTitle: ''
})

onMounted(() => {
  getDataList()
})

// 重置
const reset = () => {
  fromData.value = {
    // 表单数据
    keyword: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

const initPrice = row => {
  let val = `${row.minPrice} ~ ${row.maxPrice}`
  if (row.minPrice == row.maxPrice) {
    val = row.minPrice
  }
  return val
}

const changeColor = ()=>{
  tagForm.value.color = '#0047BB'
}

// 标签弹窗关闭
const dialogClose = () => {
  tagForm.value = {
    name: '',
    color: '',
    id: '',
    goodsId: ''
  }
  dialogForm.value.resetFields()
  tagDialog.value = false
}

// 新增标签
const addTags = row => {
  dialogTitle.value = '新增标签'
  tagForm.value.goodsId = row.id
  tagDialog.value = true
}

// 修改标签
const editTag = item => {
  dialogTitle.value = '编辑标签'
  tagForm.value = {
    name: item.name,
    color: item.color,
    id: item.id,
    goodsId: item.goodsId
  }
  tagDialog.value = true
}

// 删除标签
const deleteTag = item => {
  goodsTagDel({ id: item.id }).then(({ data: res }) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

// 标签提交
const submit = () => {
  if (!dialogForm.value) return
  dialogForm.value.validate((valid, fields) => {
    if (valid) {
      const data = {
        goodsId: tagForm.value.goodsId,
        name: tagForm.value.name,
        color: tagForm.value.color
      }
      if (tagForm.value.id == '') {
        goodsTagCreate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
            dialogClose()
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else {
        data.id = tagForm.value.id
        goodsTagUpdate(data).then(({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
            dialogClose()
          } else {
            ElMessage.error(res.msg)
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 总预约人数
const numberInput = row => {
  row.sortInput = row.sortInput.replace(/[^\d]/g, '')
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.keyword !== '') {
    data.keyword = fromData.value.keyword
  }
  queryCriteria(data)
    .then(({ data: res }) => {
      loading.value = false
      if (res.code == 200) {
        res.data.records.forEach(item => {
          item.sortVisible = false
          item.sortInput = item.sort
        })
        rTableData.value = res.data.records
        pagination.value.total = Number(res.data.pageInfo.total)
      } else {
        ElMessage.error(res.msg)
        rTableData.value = []
        pagination.value.total = 0
      }
    })
    .catch(() => {
      loading.value = false
    })
}

// 排序提交
const sortSubmit = row => {
  goodsSort({
    id: row.id,
    sort: row.sortInput
  }).then(({ data: res }) => {
    if (res.code == 200) {
      row.sortVisible = false
      ElMessage.success(res.msg)
      getDataList()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const productAdd = () => {
  router.push({
    path: '/productItem',
    query: {
      type: 3
    }
  })
}

// 详情
const detailsItem = row => {
  router.push({
    path: '/productItem',
    query: {
      id: row.id,
      type: 1
    }
  })
}

// 编辑
const editItem = row => {
  router.push({
    path: '/productItem',
    query: {
      id: row.id,
      type: 2
    }
  })
}

// 上下架
const offItem = row => {
  let msg = row.state === 1 ? '下架' : '上架'
  ElMessageBox.confirm(`确定要${msg}吗？`, '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      changeState({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 删除
const deleteItem = row => {
  ElMessageBox.confirm('确定要删除该商品吗？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      goodsDel({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 设为推荐商品
const setItem = row => {
  ElMessageBox.confirm('是否确定设为推荐商品？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      setGood({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 取消推荐商品
const cancelItem = row => {
  ElMessageBox.confirm('是否取消推荐商品？', '温馨提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      setGood({
        id: row.id
      }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  tagDialog,
  tagForm,
  tagFormRules,
  dialogTitle
} = toRefs(initData)
</script>
<style lang="scss" scoped>
.productlist {
  .btns {
    margin-bottom: 20px;
  }

  .desc {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      margin-right: 5px;
    }

    span {
      width: 300px;
      text-align: left;
    }
  }

  .sort {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-icon {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}

.inputbox {
  display: flex;
  align-items: center;

  .el-input {
    width: 160px;
    margin-right: 10px;
  }
}

:deep(.colorcont) {
  position: relative;
  .el-color-picker {
    position: absolute;
    top: 0;
    right: 0;
  }
  .el-input.is-disabled .el-input__wrapper {
    background: transparent;
    // .el-input__inner {
    //   color: #606266;
    //   -webkit-text-fill-color: #606266;
    // }
  }
}
:deep(.tabletags) {
  display: flex;
  justify-content: center;
}
:deep(.dialogForm1) {
  .el-form-item{
    display: flex !important;
  }
  .el-form-item__content {
    width: 65% !important;
  }
}
</style>
